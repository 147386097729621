 @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.img1 {
    width: 16.66%;
}

.img2 {
    width: 25%;
}

.borderImg {
    width: 12.5%;
}

.caption {
    color: white;
    font-size: 1.5rem;
}

