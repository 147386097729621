
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.ratesNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: rgba(241, 245, 245, 0.853); */
    margin-bottom: 1rem;
}

.ratesNav ul {
    position: relative;
    padding: .5rem 0;
}

.ratesNav a {
    font-size: 0.75rem;
    font-weight:bold;
}

@media  screen and (min-width:920px) {
    .ratesNav {
        position:static;
       
    }
}