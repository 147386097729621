

 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
 }

 .cardLink {
    text-decoration: none;
 }

 .cardTitle {
    text-align: center;
    /* height: 165px; */
    background:white;
    padding: 5px 5px;
 
 }

 .cardText {
   /* font-family: 'Poppins',sans-serif; */
    position: relative;
 }
 .locPaper {
   background:#f3e3e3;
   padding: 0.5em .75em;
 }