/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap'); */
 /* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Oswald:wght@500&display=swap'); */
 @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@600&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* font-family: 'Montserrat', sans-serif; */
    /* font-family: 'Oswald', sans-serif; */
    font-family: 'EB Garamond', serif;
}

.topDiv {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-bottom: 3rem;
    /* background: floralwhite; */
    /* padding-bottom: 2em; */
}

/* .contentDiv {
    display: grid;
    grid-template-columns: repeat(4,1fr);
} */

.headingSection {
    grid-column: 1/5;  
}

.heading {
    font-size: clamp(14px,2.15vw,1.75rem);
    background: lightgreen;
    text-align: center;
    padding: 0.25em 0;
    margin-bottom:0.25em;
}

.heading h4 {
    font-size: clamp(14px,1.50vw,1.5rem);
}
.paper{
    grid-column: 1 / 5;
    height: auto;
    margin: 0.25em 3em;
    padding: 0.55em  0.5em;
    line-height: 1.3;
    text-decoration: none;
    font-size: clamp(14px,1.65vw,1.5rem);
    box-shadow: 3px 3px 5px 1rem gray;
     background: whitesmoke;
}

.paper a {
    grid-column: 1 / 5;
    /* background: whitesmoke; */
}

.paper:hover {
    transform: translateY(2px);
}


