* {
	box-sizing: border-box;
	margin: 0;
	--ctrlFontSize: clamp(18px, 1.25vw, 1.45rem);
}

.container {
	padding: 0 2rem 1rem 2rem;
	display: grid;
	grid-template-columns: 150px 150px 1fr 1fr 1fr;
	grid-template-rows: repeat(6, auto);
	position: absolute;
	/* gap: 1rem; */
	row-gap: 1rem;
	margin-top: -10px;
}

.heading {
	grid-column: 1 / 4;
	font-size: clamp(14px, 1.75vw, 1.5rem);
	background: yellowgreen;
	padding: 0.5rem 0.75rem;
	width: min(50vw, 100%);
}

.result {
	grid-area: 1 / 4 / 5 / 6;
	position: relative;
	font-size: clamp(16px, 1.4vw, 1.5rem);
	font-weight: 600;
	color: black;
	padding: 0.5rem 1rem 0.5rem 1rem;
	width: min(38vw, 50%);
	margin: 0 1rem 0 4rem;
	resize: none;
}

.radioBtn1 {
	grid-area: 2 / 1 / 2 / 1;
	position: relative;
	height: 2.35rem;
	width: min(25vw, 185%);
	font-size: var(--ctrlFontSize);
	background: #ffcccc;
	padding: 0 1.25rem;
	/* box-shadow: 0.25rem 0.25rem 3rem 1rem gray; */
}

.radioBtn2 {
	grid-area: 2 / 3 / 2 / 4;
	position: relative;
	height: 2.35rem;
	width: min(25vw, 80%);
	font-size: var(--ctrlFontSize);
	background: #ffcccc;
	padding: 0 1.25rem;
	/* box-shadow: 0.25rem 0.25rem 3rem 1rem gray; */
}

.dropdown {
	grid-area: 3 / 1 / 3 / 2;
	position: relative;
	height: 2.35rem;
	width: min(25vw, 185%);
	padding-left: 1rem;
	border: none;
	border-radius: 10px;
	font-size: var(--ctrlFontSize);
}

.locDropdown {
	grid-area: 3 / 3 / 3 / 4;
	height: 2.35rem;
	width: min(25vw, 80%);
	padding-left: 1rem;
	border: none;
	border-radius: 10px;
	font-size: var(--ctrlFontSize);
}

.dateInput {
	grid-area: 4 / 3 / 4 / 3;
	height: 2.35rem;
	width: min(25vw, 80%);
	border: none;
	border-radius: 10px;
	padding-left: 1rem;
	font-size: var(--ctrlFontSize);
}

.parkTypeDropdown {
	grid-area: 4 / 1 / 4 / 2;
	height: 2.35rem;
	width: min(25vw, 185%);
	padding-left: 1rem;
	margin-left: -1px;
	border: none;
	border-radius: 10px;
	font-size: var(--ctrlFontSize);
}

.btn {
	grid-area: 5 / 1 / 5 / 1;
	height: 2.75rem;
	width: max(36vw, 30%);
	border: none;
	border-radius: 10px;
	font-size: var(--ctrlFontSize);
	font-weight: 600;
	cursor: pointer;
	background: blue;
	color: white;
}

.btn:hover {
	transition: 250ms ease-in-out 150ms;
	transform: translateY(-2px);
	background: rgb(90, 125, 121) !important;
}

.campusTooltip {
	/* position: absolute; */
	grid-area: 3 / 1 / 3 / 2;
}

.dropdown ~ .campusTooltip {
	display: none;
	position: absolute;
	/* bottom: 1%; */
	left: 10%;
	right: 5%;
	background: #430f0f;
	color: white;
	text-align: center;
	padding: 0.5em;
	border-radius: 10px;
	width: 5rem;
}

.dropdown:hover ~ .campusTooltip {
	display: block;
}

.locTooltip {
	grid-area: 3 / 3 / 3 / 4;
}

.locDropdown ~ .locTooltip {
	display: none;
	position: absolute;
	/* bottom: 1%; */
	left: 45%;
	right: 5%;
	background: #430f0f;
	color: white;
	text-align: center;
	padding: 0.5em;
	border-radius: 10px;
}

.locDropdown:hover ~ .locTooltip {
	display: block;
}

.parkTooltip {
	grid-area: 4 / 1 / 4 / 2;
}

.parkTypeDropdown ~ .parkTooltip {
	display: none;
	position: absolute;
	/* bottom: 1%; */
	left: 45%;
	right: 5%;
	background: #430f0f;
	color: white;
	text-align: center;
	padding: 0.5em;
	border-radius: 10px;
}

.parkTypeDropdown:hover ~ .parkTooltip {
	display: block;
}

.inputTooltip {
	grid-area: 4 / 3 / 4 / 4;
}

.dateInput ~ .inputTooltip {
	display: none;
	position: absolute;
	/* bottom: 1%; */
	left: 45%;
	right: 5%;
	background: #430f0f;
	color: white;
	text-align: center;
	padding: 0.5em;
	border-radius: 10px;
}

.dateInput:hover ~ .inputTooltip {
	display: block;
}

@media screen and (max-width: 960px) {
	.container {
		display: grid;
		grid-template-columns: 1fr;
	}
	.heading {
		grid-area: 1 / 1 / 1 / 1;
		font-size: clamp(14px, 1.75vw, 1.5rem);
		background: yellowgreen;
		padding: 0.5rem 0.75rem;
		margin: 1rem 0.25rem 0.25rem 0.25rem;
		width: max(40vw, 25%);
	}

	.result {
		grid-area: 2 / 1 / 2 / 1;
		font-size: clamp(16px, 1.4vw, 1.5rem);
		font-weight: 600;
		color: black;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
		/* width: max(55vw, 50%); */
		margin: 0 1rem 0 0;
	}

	.radioBtn1 {
		grid-area: 3 / 1 / 3 / 1;
		position: relative;
		height: 2.35rem;
		width: min(45vw, 185%);
		font-size: clamp(14px, 1.4vw, 1.5rem);
		background: #ffcccc;
		padding: 0.25rem 0.25rem;
		/* box-shadow: 0.25rem 0.25rem 3rem 1rem gray; */
	}

	.radioBtn2 {
		grid-area: 4 / 1 / 4 / 1;
		position: relative;
		height: 2.35rem;
		width: min(45vw, 80%);
		font-size: clamp(14px, 1.4vw, 1.5rem);
		background: #ffcccc;
		padding: 0.25rem 0.25rem;
		/* box-shadow: 0.25rem 0.25rem 3rem 1rem gray; */
	}

	.dropdown {
		grid-area: 5 / 1 / 5 / 1;
		position: relative;
		height: 2.35rem;
		width: min(45vw, 185%);
		padding-left: 1rem;
		border: none;
		border-radius: 10px;
	}

	.locDropdown {
		grid-area: 6 / 1 / 6 / 1;
		height: 2.35rem;
		width: min(45vw, 80%);
		padding-left: 1rem;
		border: none;
		border-radius: 10px;
	}

	.dateInput {
		grid-area: 8 / 1 / 8 / 1;
		height: 2.35rem;
		width: min(45vw, 80%);
		border: none;
		border-radius: 10px;
	}

	.parkTypeDropdown {
		grid-area: 7 / 1 / 7 / 1;
		height: 2.35rem;
		width: min(45vw, 185%);
		padding-left: 1rem;
		margin-left: -1px;
		border: none;
		border-radius: 10px;
	}

	.btn {
		grid-area: 9 / 1 / 9 / 1;
		height: 2.35rem;
		width: max(45vw, 30%);
		border: none;
		border-radius: 10px;
	}
}
