.tableTemplate {
	position: relative;
	/* justify-content: space-around; */
	/* align-items: center; */
}

table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

table td,
table th {
	border: 1px solid #ddd;
	padding: 8px;
}

table tr:nth-child(even) {
	background-color: #f2f2f2;
}

table tr:hover {
	background-color: #ddd;
}

table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #04aa6d;
	color: white;
}
table td {
	text-align: center;
}
table tr {
	width: 75px;
}

.navCtrls {
	border-radius: 25px;
	margin: 5px 3px;
	padding: 4px 8px;
	width: min(6.75rem, 18%);
	overflow: hidden;
	background: rgba(4, 170, 109, 0.85);
	color: white;
	box-shadow: 2px 2px 3px 2px lightgreen;
}

select {
	height: 2.4rem;
}
