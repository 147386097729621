body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  margin-top: 10px;
  width: 100%;
}

.firsttd {
  width: 150px;
}

th,
td {
  padding-bottom: 2px;
  border: solid;
  font-size: 14px;
}


#topDiv {
  /* margin-left: 20px; */
  background-color: white;
}

#bottomQuests {
  margin-top: 10px;
  margin-left: 12px;
  background-color: lightgreen;
}

img {
  height: 175px;
  width: 230px;
}

NavLink {
  background-color: whitesmoke;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: -12px;
}

#navlink {
  color: white;
  font-size: 14px;
}


#wiz {
  margin-bottom: 5px;
  margin-top: 3px;

}

#hero {
  margin-top: 3px;
  margin-bottom: 5px;

}


#carouselitem {
  background-color: black;
}

#rateDiv {
  margin-Left: -17px
}

#ratetable {
  border: ridge;
}

#locPaper {
  background-Color: white;
  padding-left: 10px;
  padding-bottom: 3px;
  padding-top: 5px;
  padding-right: 8px;

}
@media (min-width: 768px)  and (max-width: 1000px){
  .collapse {
    display: none !important; 
  }
}
#calcDataDiv{
  float: left;
  width: 48%;
  /* padding: 20px; */
  margin: 5px;
  /* border: 2px solid black; */
}
.calcBtn{
  font-size: 2rem;

  /* transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay:0s ; */

  transition: transform 0.5s ease 0s;
}
.calcBtn:hover{
  transform: translateY(-1px);
}

.flex-container {
  display: flex;
  flex-direction: row;
  gap: 1;
}

.cardTemp{
  transition: transform 0.5s ease 0s;
}
.cardTemp:hover{
  transform: translateY(-5px);
}

.mapContainer  {
  width: 100%;
  height: 250px;
}

  
