@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

.topCardDiv {
	/* background-image: url('../images/FAQ.jpg'); */
	background-position: center;
	background-size: cover no-repeat;
	background: whitesmoke;
	mix-blend-mode: multiply;
}

.divNotice {
	grid-column: 1 / 5;
	background: blanchedalmond;
	padding: 0.5em 10%;
	margin: 0.5em 1em;
	font-size: clamp(1.15rem, 1.75vw, 2rem);
	text-align: center;
	width: max(95%, 10%);
}

.cardsDiv {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	font-size: 1.25em;
	margin: 1em 1em;
	text-decoration: none;
	gap: 1rem 1rem;
	/* margin: auto 5%; */
	width: max(105rem, 10%);
}

.homeCard {
	/* height: 2500px; */
	/* display: none; */
	box-shadow: 4px 4px 3px 1rem gray;
}

.homeCard div:hover {
	transition: 250ms ease-in-out;
	transform: translateY(-3px);
}

.cardText {
	mix-blend-mode: multiply;
}

.cardTitle {
	padding: 3em 0.5em;
	height: 15dvh;
	font-size: 1.5rem;
}

.cardText:hover {
	transform: translateY(2px);
}

.question {
	margin: 0.5em 0.25em;
	background: wheat;
	font-size: 1.25rem;
	padding: 1em 5%;
	text-align: center;
}

.defSpan {
	font-weight: bold;
	font-size: 1rem;
	padding: 0.5em 0.5em;
	/* font-family: 'Montserrat', sans-serif; */
}

.question:hover {
	transform: translateY(2px);
}

@media screen and (max-width: 1650px) {
	.topCardDiv {
		grid-template-columns: repeat(4, 1fr);
	}

	.divNotice {
		grid-template-columns: repeat(4, 1fr);
	}

	.cardsDiv {
		grid-template-columns: repeat(4, 1fr);
		width: max(100px, 75%);
	}
}

@media screen and (max-width: 1200px) {
	.topCardDiv {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.divNotice {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.cardsDiv {
		grid-template-columns: 1fr 1fr 1fr;
		width: max(100px, 75%);
	}
}

@media screen and (max-width: 990px) {
	.topCardDiv {
		grid-template-columns: 1fr 1fr;
	}

	.divNotice {
		grid-template-columns: 1fr 1fr;
	}

	.cardsDiv {
		grid-template-columns: 1fr 1fr;
		width: max(325px, 60%);
	}
}

@media screen and (max-width: 768px) {
	.topCardDiv {
		grid-template-columns: 1fr;
	}

	.divNotice {
		grid-template-columns: 1fr;
	}

	.cardsDiv {
		grid-template-columns: 1fr;
		width: max(200px, 30%);
	}
}
