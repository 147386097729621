:root {
  --backcolor: rgba(169, 173, 206, 0.85);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}

.mainHeader {
  position: relative;
  text-align: center;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0.25rem 1px;
  z-index: 999;
  background: var(--backcolor);
}

.check {
  display: none;
}

.imgLink img {
  width: 6rem;
  height: 1.5rem;
  margin-top: 3px;
}

.check:checked ~ nav {
  display: block;
  transform: scale(1, 1);
}

.checkLabel {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  /* border: 1px solid red; */
  height: 100%;
  display: flex;
  align-items: center;
}

.subUl {
  display:none;
  
}

.subUl a {
  display:inline-flex;
  position:relative;
  font-size: 12px;
  color: white;
  margin-left: 1rem;
  left: 5rem;


}

.caret {
  color:#00ffff
}

.navItem-rates:hover .sub-ul {
  display: inline-block;
  transform: scale(1,1);
  transition: transform 250ms ease-in-out;
}


label #closeMenu {
  display: none;
} 

nav {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: var(--backcolor);
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

nav li {
  margin-bottom: 2em;
  margin-left: .75em;
  text-transform: uppercase;
}

nav a {
  color: black;
  text-decoration: none;
  font-size: clamp(14px,0.5rem,0.75rem);
  opacity: 0;
  /* No delay when the menu closes. When checkbox is unchecked */
  transition: opacity 550ms ease-in-out;
}

.check:checked ~ nav a {
  /* delay when the menu opens when checkbox is checked and change opaciyt to 1 from 0 */
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

.check:checked ~ .checkLabel #openMenu {
  display: none;
}
.check:checked ~ .checkLabel #closeMenu {
  display: block;
}

.navBtn {
  border-radius: 25px;
  background-color: lightblue;
}


.navItemRates:hover .subUl {
    display: block;
  }


@media screen and (min-width:1200px) {
  nav li {
      margin-right: 10px;
  }

  }

@media screen and (min-width: 990px) {

  #closeMenu {
  display: none;
}

  .checkLabel {
    display: none;
  }
  .mainHeader {
    display: grid;
    grid-template-columns: minmax(500px) 1fr 1fr 1fr;
  }

  .logo {
    grid-column: 1 / 2;
    margin-top: 5px;
  }

  nav {
    /* Takes everything that was done before to nav */
    all: unset;
    grid-column: 2 / 4;
    display: flex;
    justify-content: space-between;
  }

  nav ul {
    display: flex;
  }

  nav a {
    opacity: 1;
  }

  nav li {
    margin-left: 1.5em;
    /* padding-top: 5px; */
    margin-bottom: 0px;
  }

  /* What happens after or below the a element appears */

  nav a::after {
    content: "";
    height: 5px;
    display: block;
    background: black;
    position: relative;
    left: 0;
    top: 0;
    transform: scale(0, 1);
    transform-origin: center;
    /* When it appears it transitions using the ease in and out  */
    transition: transform ease-in-out 250ms;
  }

  nav a:hover::after {
    transform: scale(1, 1);
     transition: 250ms transform ease-in-out 250ms;
  }

  .subUl {
    display: none;
  }
  .caret {
    display: none;
  }

  .navItemRates:hover .subUl {
    display: none;
  }



}
