@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: 'Montserrat', sans-serif;
}

.cardsDiv {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1em;
	width: max(75rem, 10%);
	position: absolute;
	margin: 1rem 2.75em;
	box-shadow: 3px 3px 1rem 1rem gray;
}
.topParag {
	grid-column: 1 /4;
	background: rgb(255, 223, 230);
	color: blue;
	font-weight: bold;
	padding: 5px 5px;
	line-height: 1.5;
	font-size: clamp(12px, 1.15vw, 1.5rem);
	/* Width min() is looking for the smaller value. 
    If 70% is smaller than 1250px, it uses it. Max width is 1250px */
	width: min(1250px, 91%);
	margin: 0.5em 1em;
	padding: 0.5em 0.5em;
	box-shadow: 3px 3px 1rem 0.15rem yellowgreen;
}
.heading {
	text-decoration: underline;
	text-transform: capitalize;
	font-size: clamp(12px, 1.25vw, 1.75rem);
	width: min(1200px, 85%);
}

.title {
	font-size: clamp(14px, 1.25vw, 1.75rem);
}

.defBtn {
	font-weight: bold;
	font-size: clamp(12px, 1.25vw, 1.2rem);
	padding: 0.5em 0.5em;
	background-color: rgb(98, 15, 153);
	color: rgba(255, 251, 240, 0.82);
	width: min(13.5rem, 90%);
	box-shadow: 3px 3px 1rem blueviolet;

	/* font-family: 'Montserrat', sans-serif; */
}

.defBtn:hover {
	/* transform: translateY(2px); */
	animation: change-color 500ms ease-in-out forwards;
}

div button::before {
	content: '';
	height: 5px;
	left: 0;
	top: 0;
	display: block;
	background: black;
	position: relative;
	transform: scale(0, 1);
	transition: transform 500ms ease-in-out;
}

div button::before:hover {
	transform: scale(1, 1);
	transition: 250ms transform 250ms ease-in-out;
}

@keyframes change-color {
	0% {
		background: white;
	}

	/* 33% {
        background: yellow;
    }

    66% {
        background:black;
    }
     */
	100% {
		background: red;
	}
}

@media screen and (max-width: 1275px) {
	.cardsDiv {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		width: max(45rem, 10%);
	}
}

@media screen and (max-width: 960px) {
	.cardsDiv {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		width: max(35rem, 10%);
	}
}

@media screen and (max-width: 760px) {
	.cardsDiv {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		width: max(20rem, 10%);
	}
}
