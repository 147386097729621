* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
}

body {
    /* background-image: url('../images/garage.jpg'); */
    background:lightblue;
    /* background-blend-mode:darken; */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
}

.mainDiv {
    left: 0;
    top: 0;
    display:flex;
    flex-direction: column;
    gap: 1rem;

}


